import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

import { AppContext, clsNames, dConfirm, isEmpty, tSuccess, useUser } from 'unno-comutils'
import { Icon } from 'unno-comutils/ui'

import { MenusOnsite } from './service/menu'
import { logout } from 'unno-comutils/connect'
//import { logout } from './components/test/Connect'

export default function LayoutOnsite (props: any) {
    const user = useUser()
    const location = useLocation()
    const history = useHistory()

    // ----- ACTION

    const userLogout = (setUser: any) => {
        dConfirm('Logout ?').then(() => {
            logout().then(() => {
                tSuccess('ออกจากระบบแล้ว')
                setUser(null)
            })
        })
    }

    // ----- EVENT

    const onClickItem = (m: any, e: any) => {
        if (!e.ctrlKey) {
            e.preventDefault()
            history.push(m.url)
        }
    }

    // ----- MEMO

    const menu = useMemo(() => MenusOnsite.find(m => location.pathname.startsWith(m.url)), [location.pathname])
    const menus = useMemo(() => MenusOnsite.filter((m: any) => isEmpty(m.role) || user.allow(m.role)), [user])

    // ----- RENDER

    return <div className={clsNames('layout layout-onsite')}>
        <div className="layout-onsite-top">
            <div className="_title">
                <Icon name={'suitcase _icon'}/>
            </div>
            <div className="_menus">
                {menus.map((m: any) => <a key={'m_' + m.page} className={clsNames('_menu-item', m.page === menu?.page && '-active')} href={m.url} onClick={e => onClickItem(m, e)}>
                    <Icon name={m.icon} className="_icon"/>
                    <span className="_text">{m.name}</span>
                </a>)}
            </div>
            <div className="_user">
                <div className="_name">{user.name}</div>
                <AppContext.Consumer>
                    {({ setUser }) => <Icon name={'power-off _icon'} button onClick={() => userLogout(setUser)}/>}
                </AppContext.Consumer>
            </div>
        </div>
        <div className="layout-content">
            <div className={'_protect'}>
                {props.children}
            </div>
        </div>
    </div>
}
