import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import L from '@loadable/component'

import { AppContext, get, subscribe, tInfo, useAppStateContext } from 'unno-comutils'
import { checkLogin, logout } from 'unno-comutils/connect'
import { ToastContainer } from 'unno-comutils/Dialog'
import { User } from 'unno-comutils/utils'
import Wait from 'unno-comutils/Wait'

import MainLayout from './layout'
import LayoutOnsite from './layoutOnsite'

import 'unno-comutils/style/core.css'
import './style/app.scss'

export const fallback = <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <img src={'https://files.un-no.net/static/loading.svg'} alt={'img-loading'} style={{ width: '100px' }}/></div>

const Routing = L(() => import(/* webpackChunkName: "routing" */'./service/router'), { fallback })
const RoutingOnsite = L(() => import(/* webpackChunkName: "routerOnsite" */'./service/routerOnsite'), { fallback })

export default function App () {
    const [wait, setWait] = useState(0)
    const appState = useAppStateContext()
    useEffect(() => {
        setTimeout(() => {
            checkLogin().then((d: any) => {
                if (d.ok) {
                    setWait(1)
                    appState.setUser(new User(d.user))
                    get('app/meta', { init: 1 }).then(({ ok, __time, ...data }) => {
                        if (ok) appState.setMeta(data)
                    }).finally(() => setWait(2))
                }
                else {
                    setWait(2)
                    appState.setUser(null)
                    logout()
                }
            })
        }, 200)
    }, [])

    if (wait < 2) return <Wait color={wait ? 'blue' : 'pink'}/>
    return <AppContext.Provider value={appState}>
        <BrowserRouter>{(() => {
            if (!!appState.user) {
                if (appState.user.viewOnsite)
                    return <LayoutOnsite children={<RoutingOnsite/>}/>
                return <MainLayout children={<Routing/>}/>
            }
            return <Routing/>
        })()}
        </BrowserRouter>
        <ToastContainer/>
    </AppContext.Provider>
}
