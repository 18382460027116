import React from 'react'
import ReactDOM from 'react-dom/client'

import { unregister } from './service/worker'
import App from './app'

const root = document.getElementById('root')
if (root) ReactDOM.createRoot(root).render(<App/>)

unregister()
