import { clsNames } from 'unno-comutils'
import { Icon } from 'unno-comutils/ui'
// @ts-ignore
import * as MdIt from 'markdown-it'
// @ts-ignore
import MdIt_dl from 'markdown-it-deflist'
import { Helmet } from 'react-helmet'
import React from 'react'

export function Title (props: { icon?: string, children: string }) {
    return <Helmet>
        {props.icon
            ? <link rel="icon" href={'/static/favico/' + props.icon + '.ico'} data-react-helmet="true"/>
            : <link rel="icon" href={'/static/favicon.ico'} data-react-helmet="true"/>}
        <title>{props.children} : HQ</title>
    </Helmet>
}

export function PagingInfo (props: { paging: any }) {
    const { paging } = props
    if (!paging) return null
    return <div className={clsNames('un-paging-info', 'ml-4')}>
        <span className={'_label'}>หน้า</span>
        <span className={'_value'}>{paging.page}</span>
        <span className={'_label'}>/ {paging.pageCount}</span>
        <span className={'_label ml-2'}>รายการที่</span>
        <span
            className={'_value'}>{(paging.page - 1) * paging.rowPerPage + 1} - {paging.page * paging.rowPerPage > paging.rowCount ? paging.rowCount : paging.page * paging.rowPerPage}</span>
        <span className={'_label'}>จาก</span>
        <span className={'_value'}>{paging.rowCount}</span>
        <span className={'_label'}>รายการ</span>
    </div>
}

export function AlertPing (props: { className?: string }) {
    return <div className={clsNames('un-alert-dot', props.className)}>
        <div className="_ping"/>
        <div className="_dot"/>
    </div>
}

export function IconNew (props: { index: number, ignore?: boolean, classWidth?: string, className?: string }) {
    return <div className={clsNames('grey-blue c', props.classWidth || 'w-rn', props.className)}>{props.ignore ? <Icon name={'star orange'} solid/> : props.index + 1}</div>
}

export function Markdown (props: { children: string, className?: string }) {
    const html = MdIt({ linkify: true }).use(MdIt_dl).render(props.children?.replaceAll('<br>', '') || '')
    return <div className={clsNames('text-content', props.className)} dangerouslySetInnerHTML={{ __html: html }}/>
}

export function MockEmpty (props: { text?: string }) {
    return <div className="mock">{props.text || 'ยังไม่มีข้อมูล'}</div>
}

export function DetailItem (props: { label?: string, children: any, className?: string, classValue?: string, col?: number, offset?: number }) {
    return <div className={clsNames('detail-item', !!props.col && 'col-' + props.col, !!props.offset && 'offset-' + props.offset, props.className)}>
        <div className={'_label'}>{props.label}</div>
        <div className={clsNames('_value', props.classValue)}>{props.children || '-'}</div>
    </div>
}
